import client from "../../client";
const Api = {
  //预约列表
  getList: "/api/WarehouseService/CabinetInStorageAppointment/GetListPaged",

  //创建时的入库计划表
  getInPlanList: "/api/WarehouseService/InWarehousePlan/GetListPagedByAppoint",

  getFee: "/api/WarehouseService/CabinetInStorageAppointment/GetWarehouseOperateCostByContainerType",

  createAppointment: "/api/WarehouseService/CabinetInStorageAppointment/Create",

  getDetails: "/api/WarehouseService/CabinetInStorageAppointment/GetById",

  updateDriverPhone:"/api/WarehouseService/CabinetInStorageAppointment/EditDriverContactInfo"
};

export const getList = ({ searchKey, skipCount, maxResultCount, sorting }) => {
  return client.request({
    url: Api.getList,
    data: { searchKey, skipCount, maxResultCount, sorting },
    method: "post",
  });
};

export const getFee = ({ containerType,warehouseId }) => {
  return client.request({
    url: Api.getFee,
    data: { containerType,warehouseId },
    method: "post",
  });
};

export const getInPlanList = ({
  warehouseId,
  skipCount,
  maxResultCount,
  sorting,
}) => {
  return client.request({
    url: Api.getInPlanList,
    data: { warehouseId, skipCount, maxResultCount, sorting },
    method: "post",
  });
};

export const createAppointment = ({
  warehouseId,
  containerType,
  containerNo,
  appointmentTime,
  inWarehousePlanIds,
  payFee
}) => {
  return client.request({
    url: Api.createAppointment,
    data: {
      warehouseId,
      containerType,
      containerNo,
      appointmentTime,
      inWarehousePlanIds,
      payFee
    },
    method: "post",
  });
};

export const getDetails = ( id ) => {
  return client.request({
    url: Api.getDetails,
    data: { id },
    method: "post",
  });
};

export const updateDriverPhone = ( {id,driverTelCode,driverTelephone} ) => {
  return client.request({
    url: Api.updateDriverPhone,
    data: {id,driverTelCode,driverTelephone},
    method: "post",
  });
};

