<template>

  <Content>
    <template #contentTitle>
      {{ $t("warehouse.create_full_container_in_appointment") }}
    </template>
    <template #contentBody>

      <div>
        <a-form ref="refForm"
                :rules="rules"
                :model="state">
          <a-row>
            <a-col :sm="24"
                   :md="24"
                   :lg="9"
                   :xl="7"
                   :xxl="6">
              <a-form-item :label="$t('warehouse.select_warehouse')"
                           name="selectedWarehouse"
                           :label-col="{sm: {span: 5},md: {span: 4},lg: {span: 6},xl: {span: 6},xxl: {span: 6}}">
                <a-select v-model:value="state.selectedWarehouse"
                          @change="handleChangeWarehouse"
                          allow-clear
                          optionFilterProp="search-key"
                          show-search
                          :placeholder="$t('warehouse.warehouse_code')"
                          style="width:220px">
                  <a-select-option v-for="item in state.warehouses"
                                   :title="item.warehouseNo+(item.warehouseName)"
                                   :key="item.id"
                                   :value="item.id"
                                   :search-key="item.warehouseNo + item.warehouseName"
                                   :item="item">
                    {{item.warehouseNo}}({{item.warehouseName}})
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>

            <a-col :sm="24"
                   :md="24"
                   :lg="15"
                   :xl="15"
                   :xxl="18"
                   v-if="state.address">
              <a-form-item :label="$t('warehouse.warehouse_address')"
                           :label-col="{sm: {span: 5},md: {span: 4},lg: {span: 2},xl: {span: 2},xxl: {span: 2}}">
                {{getAddress(state.address)}} <span v-if="state.address?.linkMan">({{ getLinkman(state.address) }})</span>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :sm="24"
                   :md="24"
                   :lg="9"
                   :xl="7"
                   :xxl="6">
              <a-form-item :label="$t('warehouse.container_type')"
                           name="selectedContainerType"
                           :label-col="{sm: {span: 5},md: {span: 4},lg: {span: 6},xl: {span: 6},xxl: {span: 6}}">
                <a-select v-model:value="state.selectedContainerType"
                          @change="handleChangeContainerType"
                          allow-clear
                          style="max-width:220px"
                          :placeholder="$t('common.please_select')">
                  <a-select-option v-for="(item,index) in containerTypeEnum"
                                   :key="index"
                                   :value="item">
                    {{$t($enumLangkey('containerType',item))}}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :sm="24"
                   :md="24"
                   :lg="9"
                   :xl="7"
                   :xxl="6">
              <a-form-item :label="$t('warehouse.container_no')"
                           name="containerBoxNo"
                           :label-col="{sm: {span: 5},md: {span: 4},lg: {span: 6},xl: {span: 6},xxl: {span: 6}}">
                <a-input style="max-width:220px"
                         allow-clear
                         v-model:value="state.containerBoxNo"></a-input>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :sm="24"
                   :md="24"
                   :lg="9"
                   :xl="7"
                   :xxl="6">
              <a-form-item :label="$t('warehouse.date_of_reservation')"
                           name="date"
                           :label-col="{sm: {span: 5},md: {span: 4},lg: {span: 6},xl: {span: 6},xxl: {span: 6}}">
                <a-date-picker v-model:value="state.date"
                               style="width:150px" />
                <span class="ml-2">{{$t('warehouse.local_time')}}</span>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div class="table">
        <a-button type="primary"
                  @click="handleShowModalPlans"
                  class="mb-1"
                  ghost>{{$t('warehouse.add_in_plan')}}</a-button>
        <a-table :columns="columns"
                 :data-source="state.tableList"
                 :scroll="{ x: 800}"
                 size="small"
                 :pagination="false"
                 :rowKey="(record, index) => {return index;}">
          <template #serialNumber>
            {{ $t("warehouse.serial_number") }}
          </template>

          <template #inPlanInfo>
            <div>
              {{ $t("warehouse.plan_name") }}
            </div>
            <div>
              {{ $t("warehouse.in_warehouse_no") }}
            </div>
          </template>

          <template #inPlanInfoCustom="{record }">
            <div>
              {{record.planName}}
            </div>
            <div>
              {{record.planNo}}
            </div>
          </template>

          <template #inWarehouseStatusCustom="{record }">
            {{$t($enumLangkey('inWarehouseStatus',record.inWarehouseStatus))}}
          </template>

          <template #operate="{record }">
            <a-button @click="handleDelete(record)">{{$t('common.remove')}}</a-button>
          </template>
        </a-table>
      </div>

      <div class="mt-3">
        <div v-if="state.fee">
          <span><strong>{{$t('warehouse.landing_charges')}}:
              {{state.fee.currencyCode}}
              {{state.fee.currencySymbol}}
              {{$filters.amountToFixed2(state.fee.price)}}
            </strong></span>
        </div>
        <div class="mt-1 text-warning">
          <InfoCircleOutlined />
          {{$t('warehouse.create_in_appointment_warning')}}
        </div>
      </div>
      <div class="mt-3"
           v-if="state.fee?.breakAppointPrice>0">
        <a-checkbox v-model:checked="state.isConsentAgreement">
          {{ $t('warehouse.liquidated_damages_tips') }} {{ state.fee?.currencySymbol }}{{ $filters.amountToFixed2(state.fee?.breakAppointPrice) }}
        </a-checkbox>
      </div>

      <!-- button创建计划 -->
      <div class="mt-3 mb-5">
        <a-row type="flex"
               justify="center">
          <a-col class="mr-3">
            <a-button type="primary"
                      :disabled="!state.isConsentAgreement && state.fee?.breakAppointPrice > 0"
                      :loading="state.createLoading"
                      @click="handleCreate">{{$t('warehouse.create_appointment')}}</a-button>
          </a-col>
        </a-row>
      </div>

      <!-- 入库计划列表 -->
      <a-modal width="1000px"
               v-model:visible="inPlanModal.visible"
               :centered="true"
               :maskClosable="false"
               :ok-button-props="{ hidden:true }"
               :cancelText="$t('common.close')"
               :title="$t('warehouse.add_in_plan')">
        <a-table :columns="columns"
                 :data-source="inPlanModal.list"
                 :scroll="{ x:500,y:1000}"
                 :pagination="false"
                 size="small"
                 :rowKey="(record, index) => {return index;}"
                 :loading="inPlanModal.addLoading">
          <template #serialNumber>
            {{ $t("warehouse.serial_number") }}
          </template>
          <template #inPlanInfo>
            <div>
              {{ $t("warehouse.plan_name") }}
            </div>
            <div>
              {{ $t("warehouse.in_warehouse_no") }}
            </div>
          </template>

          <template #inPlanInfoCustom="{record }">
            <div>
              {{record.planName}}
            </div>
            <div>
              {{record.planNo}}
            </div>
          </template>

          <template #inWarehouseStatusCustom="{record }">
            {{$t($enumLangkey('inWarehouseStatus',record.inWarehouseStatus))}}
          </template>

          <template #operate="{record }">
            <a-button :disabled="true"
                      v-if="existsPlan(record)">{{$t('common.added')}}</a-button>
            <a-button v-else
                      @click="handleAddPlanToList(record)">{{$t('common.add')}}</a-button>

          </template>
        </a-table>
        <a-row type="flex"
               justify="space-around"
               align="middle"
               class="mt-3 ">
          <a-col>
            <CPager @handlePage="handlePage"
                    :showLessItems="true"
                    :pageSizeOptions="['5','10', '30']"
                    :page-data="inPlanModal.pageData"></CPager>
          </a-col>
        </a-row>
      </a-modal>

      <Payment ref="refPayment"
               :orderNo="state.containerBoxNo"
               :currencyId="state.fee?.currencyId ?? ''"
               :unpaidSymbol="state.fee?.currencySymbol ?? ''"
               :unpaidSum="state.fee?.price ?? 0"
               :payModalTitleType="5"
               @handlePayFee="handleOrderPay"
               :loading="state.payModal.loading"
               v-model:visible="state.payModal.visible" />
    </template>
  </Content>
</template>

<script>
import { reactive, onMounted, ref } from "vue";
import Content from "../../components/Content.vue";
import {
  Row, Col, Select, DatePicker,
  Table, Input, Checkbox,
  Button, Form, Modal, message
} from "ant-design-vue";
import { useI18n } from "vue-i18n/index";
import CPager from "../../components/CPager.vue";
import { getWarehouses } from "../../../api/modules/common/index";
import { getInPlanList, createAppointment, getFee } from "../../../api/modules/transportation/appointment";
import { useRouter } from 'vue-router';
import { useTab } from "../../../hooks/tabs/index";
import { getAddressByLanguageV2, currentTimeToUtc, getLinkman } from "../../../utils/general"
import { containerType as containerTypeEnum } from "../../../enum/enum.json"
import { useStore } from "vuex";
import SearchProduct from "../../components/SearchProduct.vue"
import Payment from "@/views/components/Payment.vue"
import filters from "@/filters";
export default ({
  name: "transport_appointment_create",
  components: {
    Content,
    CPager,
    SearchProduct,
    Payment,
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AInput: Input,
    AButton: Button,
    AFormItem: Form.Item,
    AForm: Form,
    AModal: Modal,
    ASelect: Select,
    ASelectOption: Select.Option,
    ADatePicker: DatePicker,
    ACheckbox: Checkbox,
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });
    const router = useRouter();
    const { delVisitedRoute } = useTab();
    const { getters } = useStore();
    const refForm = ref();
    const refPayment = ref();

    const columns = [
      {
        dataIndex: "serialNumber ",
        slots: {
          title: "serialNumber",
        },
        customRender: ({ text, record, index }) => {
          return index + 1;
        },
        fixed: "left",
        width: 60,
      },
      {
        dataIndex: "inPlanInfo",
        slots: {
          title: "inPlanInfo",
          customRender: "inPlanInfoCustom",
        },
        width: 250,
      },
      {
        title: () => vueI18n.t('warehouse.planned_quantity'),
        dataIndex: "totalWarehouseCount",
      },
      {
        title: () => vueI18n.t('warehouse.planned_box_quantity'),
        dataIndex: "totalContainerBoxCount",
      },
      {
        title: () => vueI18n.t('warehouse.product_quantity'),
        dataIndex: "totalCount",
      },
      {
        title: () => vueI18n.t('warehouse.status'),
        dataIndex: "inWarehouseStatus",
        slots: {
          customRender: "inWarehouseStatusCustom",
        },
      },
      {
        title: '',
        dataIndex: "",
        slots: {
          customRender: "operate",
        },
      },
    ];

    const state = reactive({
      address: null,
      selectedWarehouse: null,
      selectedContainerType: null,
      containerBoxNo: null,
      date: null,
      warehouses: [],
      tableList: [],
      createLoading: false,
      fee: null,
      isConsentAgreement: false,
      payModal: {
        visible: false,
        loading: false,
      },
    })

    const inPlanModal = reactive({
      visible: false,
      addLoading: false,
      loading: false,
      list: [],
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 5
      },
    })

    //-------------add plan  begin--------------
    const handleShowModalPlans = async () => {
      inPlanModal.visible = true;
      if (inPlanModal.list.length == 0) {
        handleGetInPlans();
      }
    };

    const handlePage = async (pageData) => {
      inPlanModal.pageData.skipCount = pageData.skipCount;
      inPlanModal.pageData.maxResultCount = pageData.maxResultCount;
      handleGetInPlans();
    }

    const handleGetInPlans = async () => {
      inPlanModal.addLoading = true;
      const searchData = {
        "warehouseId": state.selectedWarehouse,
        ...inPlanModal.pageData
      };
      getInPlanList(searchData).then((res) => {
        let { items, totalCount } = res.result;
        if (items) {
          inPlanModal.list = items.map(x => {
            return {
              id: x.id,
              planName: x.planName,
              planNo: x.planNo,
              totalWarehouseCount: x.total.plan.totalWarehouseCount,
              totalContainerBoxCount: x.total.plan.totalContainerBoxCount,
              totalCount: x.total.plan.totalCount,
              inWarehouseStatus: x.inWarehouseStatus,
            }
          });
          inPlanModal.pageData.totalCount = parseInt(totalCount);
        } else {
          inPlanModal.pageData.totalCount = 0;
          inPlanModal.list = [];
        }
        inPlanModal.addLoading = false;
      }).catch(() => {
        inPlanModal.addLoading = false;
      })
    };

    const existsPlan = (record) => {
      return state.tableList.findIndex(x => x.id == record.id) != -1
    }

    const handleAddPlanToList = async (record) => {
      inPlanModal.loading = true;
      if (!existsPlan(record)) {
        let waitAdd = inPlanModal.list.filter(x => x.id == record.id);
        state.tableList = state.tableList.concat(waitAdd);
        message.success(vueI18n.t("common.succeed"));
      }
      inPlanModal.loading = false;
    };

    //-------------add plan modal  end--------------
    const handleDelete = async (record) => {
      state.tableList = state.tableList.filter(item => item.id !== record.id);
    };

    const handleCreate = async () => {
      refForm.value.validate().then(() => {
        if (!state.tableList || state.tableList.length == 0) {
          message.error(vueI18n.t("warehouse.please_add_the_warehousing_plan_for_the_warehouse"));
          return 
        }

        state.payModal.visible = true
        refPayment.value.handleShowPayOrder();
      })
    }

    const funcGetWarehouses = async () => {
      getWarehouses({ warehouseType: 3 }).then((res) => {
        if (res.result && res.result.length > 0) {
          state.warehouses = res.result;
        }
      })
    }

    const handleChangeWarehouse = (value, option) => {
      handleChangeContainerType();
      if (option?.item) {
        state.address = option.item;
      } else {
        state.address = null;
      }
      // 清空入库计划
      inPlanModal.list = [];
      inPlanModal.pageData.skipCount = 0;
      inPlanModal.pageData.currentIndex = 1;

      state.tableList = []
    }

    const handleChangeContainerType = () => {
      if (!state.selectedContainerType || !state.selectedWarehouse) {
        state.fee = null;
        return
      }
      let parameter = {
        containerType: state.selectedContainerType,
        warehouseId: state.selectedWarehouse
      }
      getFee(parameter).then((res) => {
        let { result } = res;
        if (result) {
          state.fee = result;
        } else {
          state.fee = null;
        }
      }).catch(() => {
        state.selectedContainerType = null;
        state.fee = null;
      })
    }

    const getAddress = (address) => {
      return getAddressByLanguageV2(address, getters.language);
    }

    const rules = {
      selectedWarehouse: [{
        required: true,
        trigger: ['change', 'blur'],
        message: () => {
          return vueI18n.t("common.p0_is_required", [vueI18n.t("warehouse.select_warehouse")])
        }
      }],
      selectedContainerType: [{
        required: true,
        type: 'number',
        trigger: ['change', 'blur'],
        message: () => {
          return vueI18n.t("common.p0_is_required", [vueI18n.t("warehouse.container_type")])
        }
      }],
      containerBoxNo: [{
        required: true,
        trigger: ['change', 'blur'],
        message: () => {
          return vueI18n.t("common.p0_is_required", [vueI18n.t("warehouse.container_no")])
        }
      }],
      date: [{
        required: true,
        type: 'date',
        trigger: ['change', 'blur'],
        message: () => {
          return vueI18n.t("common.p0_is_required", [vueI18n.t("warehouse.date_of_reservation")])
        }
      }],
    };

    const handleOrderPay = () => {
      refForm.value
        .validate()
        .then(() => {
          if (!state.tableList || state.tableList.length == 0) {
            message.error(vueI18n.t("warehouse.please_add_the_warehousing_plan_for_the_warehouse"));
            return false
          }
          let planId = [];
          state.tableList.forEach(x => {
            planId.push(x.id);
          })

          let warehousesTimezone = state.warehouses.find(x => x.id == state.selectedWarehouse)?.timezone;

          let parameter = {
            warehouseId: state.selectedWarehouse,
            containerType: state.selectedContainerType,
            containerNo: state.containerBoxNo,
            appointmentTime: currentTimeToUtc(state.date?.format("YYYY-MM-DD 00:00:00"), warehousesTimezone), // 转为所选仓库时区的UTC时间
            inWarehousePlanIds: planId,
            payFee:filters.amountToFixed2(state.fee?.price)
          };
          state.createLoading = true;
          createAppointment(parameter).then((res) => {
            state.createLoading = false;
            if (res.result) {
              message.success(vueI18n.t("common.succeed"));
              delVisitedRoute(router.currentRoute.value);
              router.push({ name: "transport_appointment_details", params: { id: res.result } });
            }
          }).catch(() => {
            state.createLoading = false;
          })
        }).catch(() => { })
    }

    onMounted(funcGetWarehouses);

    return {
      columns,
      rules,
      state,
      inPlanModal,
      refForm,
      refPayment,
      containerTypeEnum,
      existsPlan,
      handlePage,
      handleDelete,
      handleAddPlanToList,
      handleGetInPlans,
      handleShowModalPlans,
      handleCreate,
      getAddress,
      getLinkman,
      handleChangeWarehouse,
      handleChangeContainerType,
      handleOrderPay,
    };
  }
})
</script>

<style lang="less" scoped>
</style>